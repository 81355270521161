/** @format */

@media screen and (max-width: 1920px) and (max-height: 700px) {
  .custom-decom-container {
    height: 550px;
  }
  .auth-blc .card-title {
    font-size: 38px;
  }
  .custom-decom-container .form-group .form-control {
    padding: 0.5rem 1rem;
  }
  .custom-decom-container .btn-block {
    height: 40px;
  }
}
@media screen and (max-width: 1920px) and (max-height: 550px) {
  .custom-decom-container {
    border-radius: 25px;
  }
  .custom-decom-container {
    height: 500px;
  }
}
@media screen and (max-width: 1650px) {
  .pacienti-app.todo-application
    .xray-clip-pane
    .xray-clip-footer
    .img-wrapper
    img {
    min-width: 50px;
    max-width: 50px;
  }
}

@media screen and (max-width: 1399px) {
  .card .card-title {
    font-size: 16px;
  }
  h3,
  .h3 {
    font-size: 16px;
  }
  h4,
  .h4 {
    font-size: 14px;
  }
  h5,
  .h5 {
    font-size: 12px;
  }
  .font-small-3 {
    font-size: 10px !important;
  }
}

@media screen and (max-width: 991px) {
  .custom-decom-container {
    width: calc(100% - 15px);
    height: calc(100% - 15px);
  }
}
@media screen and (max-width: 767px) {
  .pacienti-app.todo-application .tab-content {
    height: auto;
    overflow-y: auto;
  }
  .pacienti-app.todo-application .nav-tabs .nav-item .nav-link {
    min-width: 95px;
  }
  .pacienti-app.todo-application .repeter-list .repeater-block {
    display: block;
  }
  .static-input-blk {
    display: block;
  }
  .static-input-blk .inp-blk {
    margin-left: 0px;
    margin-right: 0px;
  }
  .static-input-blk .inp-blk {
    display: block;
  }
  .inp-blk-1,
  .inp-blk-2 {
    margin-right: 0;
    max-width: 100%;
    display: block;
  }
  .fc .fc-daygrid-event-harness .fc-event {
    font-size: 8px;
    font-weight: 600;
  }
  .fc .fc-daygrid-event-harness .fc-event {
    text-overflow: ellipsis;
    padding: 0px;
    overflow: hidden;
  }
  .fc-daygrid-event-dot {
    margin: 0 2px;
    border-width: calc(var(--fc-daygrid-event-dot-width, 4px) / 2);
    border-radius: 4px;
    border-radius: calc(var(--fc-daygrid-event-dot-width, 4px) / 2);
  }
  .fc .fc-button-group {
    display: inline-flex;
    vertical-align: middle;
    flex-wrap: wrap;
    align-items: center;
  }
  .fc .fc-col-header-cell-cushion {
    padding: 2px 4px;
    font-size: 8px !important;
  }
  .fc .fc-toolbar .fc-button,
  .fc .fc-toolbar .fc-button-group .fc-dayGridMonth-button,
  .fc .fc-toolbar .fc-button-group .fc-timeGridWeek-button,
  .fc .fc-toolbar .fc-button-group .fc-timeGridDay-button,
  .fc .fc-toolbar .fc-button-group .fc-listMonth-button {
    padding: 7px 12px !important;
  }
  .fc .fc-daygrid-day-bottom {
    font-size: 9px !important;
  }
  .pacienti-app.todo-application .flatpickr-calendar.inline {
    min-width: 240px;
  }
  .flatpickr-calendar.open {
    z-index: 0 !important;
  }
  .flatpickr-calendar {
    width: 300px !important;
    left: 10px !important;
  }
  .custom-decom-container .logo-block {
    top: 20px;
    left: 20px;
  }
  .custom-decom-container .logo-block img {
    width: 140px;
  }
  .custom-decom-container .main-wrap-ct {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }
  .auth-blc .card-text {
    margin-bottom: 0px;
  }
  .statistics .custom-board .ct-body {
    padding: 0;
  }
}
@media screen and (max-width: 574px) {
  .pacienti-app.todo-application .add-task.header {
    height: auto;
  }
  .pacienti-app.todo-application .add-task.header {
    padding: 1rem 1rem 1rem 1rem;
  }
  .pacienti-app.todo-application .nav-tabs .nav-item:first-child .nav-link {
    border-radius: 0px;
  }
  .pacienti-app.todo-application .nav-tabs .nav-item:last-child .nav-link {
    border-radius: 0px;
  }
  .pacient-custom-tabs-block .nav-tabs .nav-item {
    margin: 5px;
  }
  .title-box-view {
    max-width: fit-content;
    text-align: center;
    max-height: 38px;
    overflow: hidden;
    /* TEXT-OVERFLOW: ellipsis; */
    white-space: nowrap;
  }

  .title-box-view h3 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .custom-list-make .element button {
    padding: 5px 5px;
  }
  .pacienti-app.todo-application .content-area-wrapper .sidebar .todo-sidebar {
    width: 230px;
  }
}
@media screen and (max-width: 360px) {
  .day-selector .btn {
    padding: 5px !important;
  }
}
